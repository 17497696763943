import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const InfoBannerWrapper = styled.div`
  position: relative;
  z-index: 20;
  text-align: center;
  max-width: 1200px;
  margin: auto;
  line-height: 30px;
  padding: 20px 20px 30px;
  background: #f1f1f1;
`
const InfoBannerBtn = styled(Link)`
  position: absolute;
  z-index: 21;
  top: 100%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: inline-block;
  padding: 0 25px;
  background: #dedede;
  margin: auto;
  box-shadow: 2px 2px 3px 0 #c3bfbf;
  line-height: 30px;
  text-transform:uppercase;
  transition: all .3s ease;
  font-size:13px;
  text-decoration:none;
  color:black;
  font-family: "Bankgothic Regular";
  &:hover {
    background: #ece9e9;
  }
`
interface InfoBannerProps {
  className?: string
  children?: React.ReactNode
  linkLabel?: string
  linkUrl: string
}
const InfoBanner: React.FC<InfoBannerProps> = ({className,children, linkLabel, linkUrl}) => {
  return <InfoBannerWrapper className={className}>
    {children}
    <InfoBannerBtn to={linkUrl}>{linkLabel || "Read More"}</InfoBannerBtn>
  </InfoBannerWrapper>
}

export default styled(InfoBanner)`
  color: black;
  font-size:18px;
  letter-spacing: 1.3px;
  font-family: "California FB Regular";
  .logo-font {
    font-family: "Bankgothic Regular";
  }
  .red-text {
    color: red;
    font-family: "BankGothicLtBTLight";
  }
`

