import React from "react"
import styled from "styled-components"
import { SectionTitle, SpanDivider} from "../UtilComponents"
import ContactForm from "../ContactForm"

interface IContactSectionProps {
  className?: string
}

const ContactSection: React.FC<IContactSectionProps> = ({ className }) => {
  return (
    <section className={className}>
      <SectionTitle>
        Contact <SpanDivider> | </SpanDivider> Us
      </SectionTitle>
      <p className="section__subinfo">If you would like more information, please reach out using the form below </p>
      <ContactForm />
    </section>
  )
}

export default styled(ContactSection)`
  margin:0;
  padding:0 20px;
  color:black;
  text-align: center;
`
