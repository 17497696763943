import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { graphql, useStaticQuery} from "gatsby"
import { useMediaQuery } from "react-responsive"

interface IParallaxSectionProps {
  className?: string
}

const ParallaxSection: React.FC<IParallaxSectionProps> = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "ci_fotelja.jpg" }) {
          childImageSharp {
            fluid(quality: 50, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const [offsetY, setOffsetY] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      setOffsetY(window.pageYOffset)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  },[])
  return (
    <div className={className}>

        <Image style={{transform: isDesktopOrLaptop ? `translateY(-${offsetY * 0.15}px)` : 'translateY(0)' }}  className="parallax"  fluid={imageData} />

    </div>
  )
}

export default styled(ParallaxSection)`
  //height:100vh;
  width:100%;
  background:white;
  max-height:500px;
  @media screen and (min-width:1500px) {
  max-height:600px;
  }
  @media screen and (min-width:1700px) {
    max-height:700px;
  }
  overflow: hidden;
  .parallax {
    width:60%;
    margin:auto;
    //height:700px;
    background-position: center center;
    background-size: cover;
  } 
`
