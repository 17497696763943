import React from "react"
import styled from "styled-components"
import { Section, SectionTitle, SpanDivider } from "../UtilComponents"
import InfoBanner from "../InfoBanner"
import { Link } from "gatsby"

interface AboutSectionProps {
  className?: string
  sectionData?: {}
  lang: string
}

const AboutSection: React.FC<AboutSectionProps> = ({className, sectionData, lang}) => {
  return <Section className={className}>
          <SectionTitle>
            Imagine <SpanDivider> | </SpanDivider> Explore <SpanDivider> | </SpanDivider> Define
        </SectionTitle>
        <InfoBanner linkUrl="/about">
          <span className="logo-font">CASA ITALIA</span> is a uniquely flexible luxury design resource which has partnered with three extraordinary Italian fine furniture
          manufacturers - <Link className="red-text" to={sectionData["partner_group.partner1"].slug[lang]}>{sectionData["partner_group.partner1"].value[lang]}</Link>, <Link className="red-text" to={sectionData["partner_group.partner2"].slug[lang]}>{sectionData["partner_group.partner2"].value[lang]}</Link>, <Link className="red-text" to={sectionData["partner_group.partner3"].slug[lang]}>{sectionData["partner_group.partner3"].value[lang]}</Link> - to offer the highest quality design solutions to architects, designers and developers
        </InfoBanner>
    </Section>
}

export default styled(AboutSection)`
  ${SectionTitle}, ${SpanDivider} {
    color:black;
  }
`
