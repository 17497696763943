import React, { useRef, useContext, useState, useEffect } from "react"
import styled from "styled-components"
import HomeSwiperSlider from "../SwiperSlider/HomePageSlider"
import { SpanDivider } from "../UtilComponents"
import TriggerVideoBtn from "../TriggerVideoBtn/TriggerVideoBtn"
import HomePageVideo from "../Video/HomePageVideo"
import { HTMLPlyrVideoElement } from "plyr-react"
// @ts-ignore
import GlobalContext from "../GlobalProvider"

interface HeroSectionProps {
  className?: string
}

const HeroTextBannerWrapper = styled.div`
  position: absolute;
  width:80%;
  padding: 20px;
  margin:auto;
  top:3%;
  left:50%;
  z-index:100;
  text-align: center;
  p {
    font-size:18px;
    color:white;
  }
  transform:translateX(-50%)
`
interface HeroTextProps {
  fontSize?: string
}
const HeroText = styled.h2<HeroTextProps>`
  color:white;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  font-size: ${({fontSize}) => fontSize ? fontSize : 'calc(25px + (45 - 25) * ((100vw - 300px) / (900 - 300)))'};
`

const Index: React.FC<HeroSectionProps> = ({ className, slider }) => {
  // const [initialLoad, setInitialLoad] = useState(false) 267909138
  const [openVideo,setOpenVideo] = useState(false)
  const [videoSource, setVideoSource] = useState("786047609")
  // const globalContext = useContext(GlobalContext)
  const videoRef = useRef<HTMLPlyrVideoElement>()
  const videoWrapper = useRef<HTMLDivElement | undefined>()
  const handleClick = (source:string, e) => {
    setVideoSource(source)
    setOpenVideo(true)
    // if(initialLoad) {
    //   if(videoRef && videoRef.current) {
    //     videoRef?.current?.plyr?.play()
    //   }
    //   if(videoWrapper && videoWrapper.current) {
    //     videoWrapper.current.classList.add("open")
    //   }
    //   // @ts-ignore
    //   globalContext.setIsVideoPlaying(true)
    // }
    // if(videoRef && videoRef.current) {
    //   videoRef?.current?.plyr?.play()
    // }
    // if(videoWrapper && videoWrapper.current) {
    //   videoWrapper.current.classList.add("open")
    // }
    // // @ts-ignore
    // globalContext.setIsVideoPlaying(true)
  }
  const closeVideo = () => {
    setOpenVideo(false)
    // if(videoWrapper && videoWrapper.current) {
    //   videoWrapper.current.classList.remove("open")
    // }
    if(videoRef && videoRef.current) {
      videoRef?.current?.plyr?.stop()
    }
    // // @ts-ignore
    // globalContext.setIsVideoPlaying(false)
  }
  return (
    <div className={className}>
      <div className="hero">
        <div ref={videoWrapper} className={`promo-video ${openVideo ? 'open': null}`}>
          { openVideo ? <HomePageVideo opened={openVideo} source={videoSource} handleClose={closeVideo} ref={videoRef} /> : null}
        </div>

        <TriggerVideoBtn backgroundColor="transparent" size="45px" handleClick={handleClick.bind(null, "786047609")} />
        <HomeSwiperSlider slider={slider} />
        <HeroTextBannerWrapper>
          <HeroText fontSize="45px">Casa <SpanDivider fontSize="55px">|</SpanDivider> Italia </HeroText>
          <p>Design is a process of discovery and we would like to invite you to an exciting journey with us</p>
        </HeroTextBannerWrapper>
      </div>
    </div>
  )
}

export default styled(Index)`
  .hero {
    position: relative;
    display: flex;
    width: 100%;
    max-height: 100vh;
    height: calc(100vh - 61px);
  }
  .promo-video {
    padding:100px 2% 2%;
    @media screen and (min-width:900px) and (max-width:1050px) {
      padding-top:150px;
    }
    @media screen and (min-width:1050px) {
      padding-top:7%;
    }
    // max-width:1200px;
    position: fixed;
    // left:50%;
    // top:50%;
    // transform:translate(-50%, -50%);
    left:0;
    top:0;
    z-index:2000;
    width:100%;
    height:100%;
    height:100%;
    display:none;
    background:rgba(0,0,0,.9);
    &.open {
      display:block;
    }
  }
  //overflow:hidden;
`
