import { graphql, useStaticQuery } from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { GatsbyImageSharpFluidFragment, Maybe } from "../../../graphql-types"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import SwiperCore, { Navigation , Autoplay } from 'swiper';
import { NavNextButton, NavPrevtButton } from "./SliderNavigationButtons"

SwiperCore.use([Navigation, Autoplay]);


export type SliderImagesQuery = (
  { __typename?: 'Query' }
  & { placeholderImages?: Maybe<(
    { nodes: Maybe<(
        { __typename?: 'File' }
        & { childImageSharp?: Maybe<(
          { __typename?: 'ImageSharp' }
          & { fluid?: Maybe<(
            { __typename?: 'ImageSharpFluid' }
            & GatsbyImageSharpFluidFragment
            )> }
          )> }
        )>[] }
    )> }
  );

interface SwiperSliderProps {
  className?: string
  images: [{}]
}

const FimesHomeSlider: React.FC<SwiperSliderProps> = ({className, images}) => {
  const params = {
    navigation: {
      nextEl: '.fimes-btn-next',
      prevEl: '.fimes-btn-prev'
    },
    // renderPrevButton: () => <NavPrevtButton className="swiper-btn-prev">Prev</NavPrevtButton>,
    // renderNextButton: () => <NavNextButton className="swiper-btn-next">Next</NavNextButton>,
  }
  const data = useStaticQuery<SliderImagesQuery>(graphql`
    query FimesSliderImages {
      placeholderImages: allFile(filter: {relativeDirectory: {eq: "brands/fimes"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  // const imageFluid = data?.placeholderImage?.childImageSharp?.fluid || null
  const fluidImages = data?.placeholderImages?.nodes?.map(pi => {
    return pi?.childImageSharp?.fluid
  })

  return <div className={className}>
    { images.length > 0 ? <Swiper
      {...params}
    >
      { images.map((fi, index) => {
        const galleryIMAGE = getImage(fi.galleryImage)
        return <SwiperSlide key={index}
        >
          <GatsbyImage image={galleryIMAGE} alt="slider" />
        </SwiperSlide>
      })}
      <NavPrevtButton addclass="fimes-btn-prev" />
      <NavNextButton addclass="fimes-btn-next" />
    </Swiper> : null }
  </div>
}
export default styled(FimesHomeSlider)`
  position:relative;
  width:100%;
  height:100%;
  background-position: center center;
  background-size: cover;
  .swiper-button-prev:after, .swiper-button-next:after {
    font-size:30px;
    color:white;
  }
  .next, .prev {
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    z-index:300;
  }
  .next {
    right:20px;
  }
  .prev {
    left:20px;
  }
`
