import { graphql, useStaticQuery} from "gatsby"
import { FluidObject } from "gatsby-image"
import React from "react"
import styled from "styled-components"
import { GatsbyImageSharpFluidFragment, Maybe } from "../../../graphql-types"
import BackgroundImage from 'gatsby-background-image'
import { NavNextButton, NavPrevtButton } from "./SliderNavigationButtons"
// import Swiper from "react-id-swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import SwiperCore, { Navigation , Autoplay } from 'swiper/core';
// import { Swiper , SwiperSlide } from "swiper/react"
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
// import 'swiper/components/scrollbar/scrollbar.scss';
import { FaChevronLeft, FaChevronRight, } from "react-icons/fa"
import { sortModulesAndAssets } from "../../../plugins/berry-plugin/util/helpers.js"

SwiperCore.use([Navigation, Autoplay]);

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export type SliderImagesQuery = (
  { __typename?: 'Query' }
  & { placeholderImages?: Maybe<(
    { nodes: Maybe<(
        { __typename?: 'File' }
        & { childImageSharp?: Maybe<(
          { __typename?: 'ImageSharp' }
          & { fluid?: Maybe<(
            { __typename?: 'ImageSharpFluid' }
            & GatsbyImageSharpFluidFragment
            )> }
          )> }
        )>[] }
    )> }
  );

interface SwiperSliderProps {
  className?: string
}

const SwiperSlider: React.FC<SwiperSliderProps> = ({className, slider}) => {
  const params = {
    style: {height:"100%"},
    navigation: {
      nextEl: '.home-btn-next',
      prevEl: '.home-btn-prev'
    },
    // renderPrevButton: () => <NavPrevtButton className="swiper-btn-prev">Prev</NavPrevtButton>,
    // renderNextButton: () => <NavNextButton className="swiper-btn-next">Next</NavNextButton>,
  }
  const data = useStaticQuery<SliderImagesQuery>(graphql`
    query SliderImages {
      placeholderImages: allFile(filter: {relativeDirectory: {eq: "slider"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  // const imageFluid = data?.placeholderImage?.childImageSharp?.fluid || null
  const fluidImages = data?.placeholderImages?.nodes?.map(pi => {
    return pi?.childImageSharp?.fluid
  })
  // console.log("SLIDER: ", slider);
  /**
   * The `as FluidObject` is not ideal.
   * The typings for GatsbyImage do not match the
   * typings generated for GatsbyImageSharpFluid
   * Fields can be null from the query but are required for GatsbyImage
   * See if PR https://github.com/gatsbyjs/gatsby/pull/17065 has been merged
   */
  // return imageFluid != null ? (
  //   <GatsbyImage fluid={imageFluid as FluidObject} />
  // ) : null
  return <div className={className}>
    { slider ? <Swiper {...params }
      // style={{
      //   height:"100%"
      // }}
      // navigation
      // loop={true}
      // autoplay={{
      //   delay: 5000
      // }}
    >
      { sortModulesAndAssets(slider).map((fi, index) => {
        return <SwiperSlide key={index}
        >
          {/*<GatsbyImage fluid={fi as FluidObject} />*/}
          <BackgroundImage
            Tag="div"
            className={className}
            fluid={fi.galleryImage.childImageSharp.fluid as FluidObject}
          >
          </BackgroundImage>
        </SwiperSlide>
        // return <BackgroundImage
        //   Tag="div"
        //   className={className}
        //   fluid={fi as FluidObject}
        // >
        // </BackgroundImage>
      })}
      <NavPrevtButton addclass="home-btn-prev" />
      <NavNextButton addclass="home-btn-next" />
      {/*<div className="next">*/}
      {/*  <FaChevronRight size="30" />*/}
      {/*</div>*/}
      {/*<div className="prev">*/}
      {/*  <FaChevronLeft size="30" />*/}
      {/*</div>*/}
    </Swiper> : null }
  </div>
}
export default styled(SwiperSlider)`
  position:relative;
  width:100%;
  height:100%;
  background-position: center center;
  background-size: cover;
  .swiper-container {
    height:100%;
  }
  .swiper-slide {
    width:100% !important;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    font-size:30px;
    color:white;
  }
`
