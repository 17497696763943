import { graphql, Link } from "gatsby"
import React from "react"
import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProps } from "gatsby"
import HeroSection from "../components/HeroSection"
import AboutSection from "../components/AboutSection"
import ParallaxSection from "../components/ParallaxSection/ParallaxSection"
import TeamSection from "../components/TeamSection/TeamSection"
import PartnerSection from "../components/PartnerSection"
import ProjectSection from "../components/ProjectSection"
import PressSection from "../components/PressSection"
import ContactSection from "../components/ContactSection"
import Helmet from "react-helmet"
// import { TemplateLocales } from "../types"
// @ts-ignore
import { getSectionsData} from "../util/helpers"



interface IPageProps extends PageProps<object, {lang: string, slug: string, templateName: string}> {

}

const IndexPage: React.FC<IPageProps> = ({ location:{pathname:path}, data, pageContext }) => {
  // @ts-ignore
  const secData = getSectionsData(data.template.sections)
  // @ts-ignore
  return (<Layout page={path} lang={pageContext.lang}>
    <SEO title="Luxury furniture design company with three extraordinary Italian fine furniture partners"
          description="Casa Italia offers the highest quality furniture and design solutions to architects, designers and
developers."
          keywords="Italian furniture, Luxury furniture, Design resource, Fine furniture, Fimes, Mussi, MK"
    />
    <Helmet>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css" />
    </Helmet>
    <HeroSection slider={secData.slider.selectedModules[0].gallery.galleryImages} />
    <AboutSection sectionData={secData.about} lang={pageContext.lang} />
    <ParallaxSection />
    <TeamSection sectionData={secData.team} lang={pageContext.lang} />
    <PartnerSection sectionData={secData.partners} lang={pageContext.lang} />
    <ProjectSection sectionData={secData.portfolio} lang={pageContext.lang} />
    <PressSection />
    <ContactSection />
    <Helmet>
      <script
        src="https://code.jquery.com/jquery-3.6.0.min.js"
        integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
        crossOrigin="anonymous" />
      <script src="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js" />
    </Helmet>
  </Layout>)
}

export const query = graphql`
  {
    template(name: {eq: "index"}) {
      sections {
        keyPrefix
        items {
          key
          ... on DataItemTextGet {
            __typename
            locales {
              iso
              value
            }
          }
          ... on DataItemAssetGet {
            __typename
            value {
              locales {
                alt
                description
                iso
                title
              }
            }
            imageFile {
              publicURL
            }
          }
          ... on DataItemLinkGet {
            __typename
            locales {
              iso
              value
            }
            slugs {
              iso
              value
            }
          }
        }
        ... on SectionModuleGet {
          __typename
          selectedModules {
            additionalData {
              key
              locales {
                iso
                value
              }
            }
            locales {
              content
              description
              iso
              metaDescription
              metaKeywords
              metaTitle
              title
              value
            }
            ... on MemberGet {
              uid
              memberName
              memberSurname
              imageFile {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, transformOptions: {cropFocus: CENTER}, quality: 90)
                }
                publicURL
              }
              gallery {
                galleryImages {
                   position
                   galleryImage {
                      publicURL
                   }
                }
              }
            }
            ... on PartnerGet {
              uid
              representativeName
              url
              logoImageFile {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, quality: 90)
                  }
                }
              logo {
                uri
              }
              organizationName
              gallery {
                galleryImages {
                  position
                  galleryImage {
                    childImageSharp {
                      fluid {
                         ...GatsbyImageSharpFluid
                      }
                        gatsbyImageData(aspectRatio: 1.5, layout: CONSTRAINED, transformOptions: {cropFocus: CENTER}, quality: 90)
                    }
                  }
                }
              }
            }
            ... on GalleryModuleGet {
              uid
              type
              gallery {
                galleryImages {
                  position
                  galleryImage {
                     childImageSharp {
                        fluid(quality: 100, maxWidth:1980) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                  }
                }
              }
            }
            ... on PortfolioGet {
              uid
              portfolioUrl
              position  
              imageFile {
              childImageSharp {
                  gatsbyImageData(aspectRatio: 1.5, layout: CONSTRAINED, transformOptions: {cropFocus: CENTER}, quality: 90)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
