import React from "react"
import styled from "styled-components"
import InfoBanner from "../InfoBanner"
import { Link } from "gatsby"

interface IPressSectionProps {
  className?: string
}

const PressSection: React.FC<IPressSectionProps> = ({ className }) => {
  return (
    <section className={className}>
      <InfoBanner linkUrl="/press" linkLabel="In The Press">
        <p className="section__showcase">All of us at <span className="logo-font">Casa Italia</span>, along with our
          trusted partners, are committed to excellence at every stage of the process
          in an effort to create and nurture life long relationships with our clients</p>
      </InfoBanner>
    </section>
  )
}

export default styled(PressSection)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 5% 60px 5%;
  position: relative;
`
