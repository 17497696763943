import React from "react"
import styled from "styled-components"
import { SectionTitle, SpanDivider } from "../UtilComponents"
import ProjectList from "../ProjectList"


interface IProjectSectionProps {
  className?: string
  sectionData?: {
    selectedModules?: {}[]
  }
  subinfo?: string
  lang: string
}

const ProjectSection: React.FC<IProjectSectionProps> = ({ className, lang, sectionData, subinfo }) => {
  // @ts-ignore
  return (
    <section className={className}>
      <SectionTitle>
        Explore our <SpanDivider> | </SpanDivider> Projects
        {subinfo &&
        <p className="subheader-text">{subinfo}</p> }
      </SectionTitle>
      <p className="section__subinfo">{sectionData.description[lang]}</p>
      <ProjectList projects={sectionData?.selectedModules ? sectionData.selectedModules : sectionData.nodes} lang={lang} />
    </section>
  )
}

export default styled(ProjectSection)`
  background-color: #f1f1f1;
  color:black;
  //padding-top: 30px;
  //padding-bottom: 60px;
  padding: 30px 5% 60px 5%;
  text-align: center;
  margin: 5% 0;
  position: relative;
  .subheader-text {
    width:70%;
    margin:auto;
    line-height: 30px;
    font-size:18px;
  }
`
