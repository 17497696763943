import React from "react"
import styled from "styled-components"
import { SectionTitle, SpanDivider } from "../UtilComponents"
import FimesHomeSlider from "../SwiperSlider/FimesHomeSlider"
import Img from "gatsby-image"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import Fade from "react-reveal/Fade"

interface IPartnerSectionProps {
  className?: string
  sectionData?: {}
  lang: string
}

const PartnerSection : React.FC<IPartnerSectionProps> = ({ className,lang, sectionData }) => {
  return (
    <section className={className}>
      <SectionTitle>
        Discover our <SpanDivider> | </SpanDivider> Partner Brands
      </SectionTitle>
      {/*<p className="section__subinfo">Striving for excellence <span className="logo-font">Casa Italia</span> partnered*/}
      {/*  with three extraordinary Italian fine furniture manufacturers to offer superb design solutions to architects,*/}
      {/*  designers and developers</p>*/}
        <div style={{lineHeight:"24px"}} dangerouslySetInnerHTML={{__html: sectionData.description[lang]}} />
      <div className="partners__boxes">
        {sectionData.selectedModules.length > 0 && sectionData.selectedModules.map(sm => {
          const logoImg = getImage(sm.logoImageFile)
          return <Fade key={sm.uid} bottom>
            <div className="partners__box">
              <div className="partners__member-pic">
                <FimesHomeSlider images={sm.gallery.galleryImages} />
              </div>
              <div className="partners__member-info" style={{paddingBottom:"23px"}}>
                <span className="title">{sm.locales.find(l => l.iso === lang).title}</span>
                <div dangerouslySetInnerHTML={{__html: `${sm.locales.find(l => l.iso === lang).content}`}} />
                <br />
              </div>
              <div className="partners__logo">
                {/*<Img fluid={sm.logoImageFile.childImageSharp.fluid} alt="" />*/}
                <GatsbyImage alt={sm.title} image={logoImg} />
              </div>
            </div>
          </Fade>
        })}
      </div>
    </section>
  )
}

export default styled(PartnerSection)`
  text-align: center;
  padding: 0 5%;
  margin: 5% 0;
  position: relative;
  color: black;
  .partners {
    &__boxes {
      margin-top:50px;
      display:flex;
      flex-wrap:wrap;
      @media screen and (min-width:900px) {
        flex-wrap:nowrap;
      }
    }
    &__box {
      position: relative;
      width:100%;
      margin-top:100px;
      background-color:#f1f1f1;
      &:first-child {
        margin-top:30px;
      }
      @media screen and (min-width:900px) {
        width:32%;
        margin-right:2%;
        margin-top:0px;
        &:first-child {
          margin-top:0;
        }
        &:last-child {
          margin-right:0;
        }
      }
    }
    &__member-pic {
      width:100%;
      padding:10px;
      background: #E5E5E5;
      img {
        display:block;
      }
    }
    &__member-info {
      a  {
        font-family: BankGothicLtBTLight;
      }
      span {
        display:block;
        &.title {
          font-family: 'Bankgothic Regular';
          color:red;
          font-weight:bold;
          text-transform: uppercase;
          margin-bottom:30px;
        }
      }
      padding:35px 10px;
      @media screen and (min-width:900px){
        padding-left:20px;
        padding-right:20px;
      }
      background: #f1f1f1;
      p {
        // font-weight: bold;
        line-height:25px;
      }
    }
    &__logo {
      position: absolute;
      bottom:100%;
      left:50%;
      transform: translate(-50%, 50%);
      width:40%;
      padding:8px 0;
      background:#f1f1f1;
      z-index:100;
    }
  }
`
